import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { Box, LinkButton, Heading, Button, Spinner } from '@components/Primitives';
import { Form } from '@components/Form';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { Layout } from '@components/Layout';

const MainHeading = styled(Heading)`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const EmailText = styled.p`
  margin-top: 10px;
  font-weight: bold;
`;

const SmallGreyText = styled.p`
  font-size: 14px;
  color: grey;
  margin: 10px 0;
`;

const ChangePassword: React.FC = () => {
  const { authStore: { getAuthUrlPwd, getPasswordResetInfo, updatePassword, authUrlPwd } } = useStores();
  const [error, setError] = useState<string>(''); // Error for immediate state change
  const [delayedError, setDelayedError] = useState<string>(''); // Error that is delayed
  const [loading, setLoading] = useState<boolean>(true);
  const [passwordResetToken, setPasswordResetToken] = useState<string>('');
  const [email, setEmail] = useState<string>();
  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (error === 'passwordsDoNotMatch') {
        setDelayedError('passwordsDoNotMatch');
      } else {
        setDelayedError(''); // Clear delayed error if condition is corrected
      }
    }, 1000); // Delay of 1 second for showing error

    return () => clearTimeout(timeoutId); // Reset timer if condition changes
  }, [error]); // Re-run whenever the error state changes

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const state = params.get('state');
    const code = params.get('code');
    
    if (state && code) {
      handleCallback(state, code);
    } else {
      setError('common.invalidCallbackParameters');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAuthUrlPwd(window.location.origin + '/change-password/');
  }, [authUrlPwd]);

  const handleCallback = async (state: string, code: string) => {
    try {
      const params = `code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}`;
      const response = await getPasswordResetInfo(params);
      setPasswordResetToken(response.passwordResetToken);
      setEmail(response.email || 'Placeholder email');
      setStep(2); // Move to Step 2: Confirm Email
    } catch (error) {
      console.error('Error during validation:', error);
      setError('common.authFailed');
    } finally {
      setLoading(false);
    }
  };

  const validate = (values: { newPassword?: string; newPasswordAgain?: string }) => {
    const errors: { newPassword?: string; newPasswordAgain?: string } = {};

    if (!values.newPassword) {
      errors.newPassword = 'Password is required';
      setError('passwordRequired');
    } else if (values.newPassword !== values.newPasswordAgain) {
      errors.newPasswordAgain = 'Passwords must match';
      setError('passwordsDoNotMatch');
    } else {
      // Clear both error states immediately if passwords match
      setError('');
      setDelayedError('');
    }

    return errors;
  };

  const handleSubmit = async (formValues: { newPassword: string; newPasswordAgain: string }, callback: () => void) => {
    const { newPassword, newPasswordAgain } = formValues;
  
    if (newPassword !== newPasswordAgain) {
      setError('passwordsDoNotMatch');
      return;
    }
  
    if (!passwordResetToken) {
      setError('missingToken');
      return;
    }
  
    const password = newPassword;
  
    try {
      setLoading(true);  // Only set loading when proceeding with valid form
      await updatePassword(passwordResetToken, password, callback);
      setStep(4); // Move to success message step
    } catch (error) {
      console.error('Error during password update:', error);
      setError('common.updatePasswordFailed');
    } finally {
      setLoading(false);  // Stop the loading spinner after submission attempt
    }
  };

  const t = (id: string) => `pages.changePassword.${id}`;
  const tCommon = (id: string) => `common.${id}`;

  const formFields = [
    {
      id: 'newPassword',
      name: 'password',
      type: 'password',
      required: true,
    },
    {
      id: 'newPasswordAgain',
      name: 'confirmPassword',
      type: 'password',
      required: true,
    },
  ];

  return (
    <Layout twoColumnLayout>
      {loading && (
        <Spinner /> // Keep layout intact, show loading spinner
      )}
      {!loading && (
        <Box pl="2" pr="2" textAlign="center">
          {/* Render content based on the current step */}
          {step === 1 && (
            <>
              <MainHeading level="3">
                <M id={t('title')} />
              </MainHeading>
              <LinkButton mt="2" mb="2" href={authUrlPwd}>
                <M id={t('gotoIdentificationService')} />
              </LinkButton>
            </>
          )}

          {step === 2 && (
            <>
              <MainHeading level="3">
                <M id={t('confirmEmailTitle')} />
              </MainHeading>
              <p><M id={t('emailConfirmation')} /></p>
              <EmailText>{email}</EmailText>
              <ButtonContainer>
                <Button onClick={() => setStep(3)}>
                  <M id={tCommon('proceed')} />
                </Button>
                <Button variant="secondary" onClick={() => navigate('/')}>
                  <M id={tCommon('cancel')} />
                </Button>
              </ButtonContainer>
            </>
          )}

          {step === 3 && (
            <>
              <MainHeading level="3" mb="4">
                <M id={t('title')} />
              </MainHeading>
              <Form
                id="change-password"
                fields={formFields}
                values={{}}
                onSubmit={handleSubmit}
                submitText={t('confirmNewPassword')}
                styles={['centerLabels', 'singleColumn']}
                validate={validate}
              />
              {delayedError && (
              <p style={{ color: 'red', minHeight: '20px', visibility: delayedError ? 'visible' : 'hidden' }}>
                <M id={tCommon(delayedError)} />
              </p>
            )}

            </>
          )}

          {step === 4 && (
            <>
              <MainHeading level="3" mb="4">
                <M id={t('successMessageTitle')} />
              </MainHeading>
              <p><M id={t('activateAccountInstructions')} /></p>
              <SmallGreyText><M id={t('checkSpamInstructions')} /></SmallGreyText>
              <p><M id={t('accountReadyInstructions')} /></p>
              <LinkButton mt="2" href="/login">
                <M id={t('goToLogin')} />
              </LinkButton>
            </>
          )}
        </Box>
      )}
    </Layout>
  );
};

export default observer(ChangePassword);
